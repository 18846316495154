<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="교재 관리">
            <div class="float-right">
                <div class="btn-ibbox title-btnbox dp-inblock mr-10">
                    <router-link to="TextbookReturnList" target="_blank"><button class="btn-default">교재 반납 관리</button></router-link>
                </div>
                <div class="btn-ibbox title-btnbox dp-inblock">
                    <router-link to="TextbookStatisticsList" target="_blank"><button class="btn-default">교재 출고 현황</button></router-link>
                </div>
            </div>
            - 교재명 클릭 시 해당 교재의 입/출/반납 내역 확인이 가능합니다.<br>
            - <span class="bold">[입고 등록]</span> 버튼을 클릭 시 교재 입고 등록이 가능합니다. 
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <table class="table-row table-serach">
                        <tbody>
                            <tr>
                                <th width="180">수정일</th>
                                <td>
                                    <carrot-date-picker v-model="bbs.sdate" class="w-110px dp-inblock"></carrot-date-picker> 
                                    ~ 
                                    <carrot-date-picker v-model="bbs.edate" class="w-110px dp-inblock"></carrot-date-picker>
                                </td>
                                <th width="180">출판사</th>
                                <td>
                                    <input type="text" v-model.trim="bbs.publisher" class="w-80per">
                                </td>
                            </tr>
                            <tr>
                                <th width="180">교재 명</th>
                                <td>
                                    <carrot-book-title v-model:bookname.sync="bbs.title" v-model="bbs.idx_title" class="w-80per"></carrot-book-title>
                                </td>
                                <th width="180">카테고리</th>
                                <td>
                                    <carrot-book-category v-model="bbs.category" class="w-80per"></carrot-book-category>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button @click="bbs.doInit" class="btn-default float-right mt-10">검색</button>
                    <div class="clear"></div>

                    <div class="mt-40 mb-20">
                        <select v-model="bbs.btype" @change="bbs.doInit" class="w-100px float-left">
                            <option value="">구분 전체</option>
                            <option value="내부">내부</option>
                            <option value="외부">외부</option>
                        </select>
                        
                        <button v-if="bbs.isAuth==true" @click="bbs.addBook" class="btn-default float-right">입고 등록</button>
                        <button v-if="bbs.isAuth==true" @click="bbs.doDelete" class="btn-default float-right mr-20">폐기</button>
                        <div class="clear"></div>
                    </div>

                    <table class="table-col">
                        <colgroup>
                            <col width="40">
                            <col width="60">
                            <col width="80">
                            <col width="200">
                            <col width="*">
                            <col width="*">
                            <col width="100">
                            <col width="120">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    <label><input type="checkbox" @change="bbs.checkAll" v-model="bbs.isAll"></label>
                                </th>
                                <th>No</th>
                                <th>구분</th>
                                <th>출판사</th>
                                <th>카테고리</th>
                                <th>교재명</th>
                                <th>재고수량</th>
                                <th>최근 수정일</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>
                                    <label><input type="checkbox" v-model="bbs.selected" :value="irow.idx"></label>
                                </td>
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.btype }}</td>
                                <td>{{ irow.publisher }}</td>
                                <td>{{ irow.book_category?irow.book_category:'-' }}</td>
                                <td><span @click="bbs.showBook(i)" class="btn-view">{{ irow.title }}</span></td>
                                <td>{{ irow.qty }}</td>
                                <td>{{ irow.udate }}</td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="8">등록된 교재가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotBookCategory from '@/components/common/CarrotBookCategory.vue'
import CarrotBookTitle from '@/components/common/CarrotBookTitle.vue'
import Swal from 'sweetalert2'
import { useStore } from 'vuex';


export default {
    layout: 'ResourceManagement',
    components: {
        CarrotDatePicker,
        CarrotBookCategory,
        CarrotBookTitle,
    },
    setup() {
        const router = useRouter();
        const toast  = useToast()
        const store  = useStore();

        const bbs = reactive({
            isAuth    : false,
            isMounted : false,
            isAll : false,
            selected : [],
            page : 1,
            rows : 50,

            sdate     : "",
            edate     : "",
            publisher : "",
            title     : "",
            category  : 0,
            btype     : "",

            list : [], total : 0,

            checkAll : () => {
                if( bbs.isAll == true ){
                    for(let i=0; i<bbs.list.length; i++){
                        let irow = bbs.list[i];
                        bbs.selected.push(irow.idx);
                    }
                } else {
                    bbs.isAll    = false;
                    bbs.selected = [];
                }
            },

            doDelete : () => {
                if( bbs.selected.length <= 0 ){
                    Swal.fire({
                        title : '폐기',
                        text  : '폐기할 교재를 선택하세요.'
                    });
                    return;
                }

                Swal.fire({
                    title : '폐기',
                    text  : '총 ' + bbs.selected.length + '건의 교재를 폐기하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        let params = {
                            selected : bbs.selected
                        };
                        axios.post("/rest/resourceManagement/delBooks", params).then((res) => {
                            if( res.data.err == 0 ){
                                toast.error("폐기하였습니다.");
                                bbs.doInit();
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        })
                    }
                })
            },

            showBook : (i) => {
                let o = bbs.list[i];
                sessionStorage.setItem('TextbookMaintenanceList', JSON.stringify({
                    page : bbs.page,
                    rows : bbs.rows,
                    sdate     : bbs.sdate,
                    edate     : bbs.edate,
                    publisher : bbs.publisher,
                    title     : bbs.title,
                    category  : bbs.category,
                    btype     : bbs.btype,
                }));
                router.push({
                    name : 'ResourceManagement-TextbookMaintenanceView-idx',
                    params : {
                        idx : o.idx
                    }
                });
            },

            addBook : () => {
                sessionStorage.setItem('TextbookMaintenanceList', JSON.stringify({
                    page : bbs.page,
                    rows : bbs.rows,
                    sdate     : bbs.sdate,
                    edate     : bbs.edate,
                    publisher : bbs.publisher,
                    title     : bbs.title,
                    category  : bbs.category,
                    btype     : bbs.btype,
                }));
                router.push({
                    name : 'ResourceManagement-textbookMaintenanceAdd'
                });
            },

            doInit : () => {
                bbs.page = 1;
                bbs.isAll = false;
                bbs.selected = [];
                bbs.doSearch();
            },

            doSearch : () => {
                if( bbs.isMounted == false ) return;
                let params = {
                    page : bbs.page,
                    rows : bbs.rows,
                    sdate     : bbs.sdate,
                    edate     : bbs.edate,
                    publisher : bbs.publisher,
                    title     : bbs.title,
                    category  : bbs.category,
                    btype     : bbs.btype,
                };
                axios.get('/rest/resourceManagement/getBookList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;

                        bbs.isAll = false;
                        bbs.selected = [];
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },


        });


        onMounted(() => {
            // Mounted
            if( store.state.isManageSupportAdmin == false ) {
                router.go(-1);
                Swal.fire({
                    title : '교재 관리',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }else{
                bbs.isAuth = true;
            }

            bbs.isMounted = true;

            let ss = sessionStorage.getItem('TextbookMaintenanceList');
            if( ss ){
                ss = JSON.parse(ss);
                if( ss.page ) bbs.page = ss.page;
                if( ss.rows ) bbs.rows = ss.rows;
                if( ss.sdate     ) bbs.sdate     = ss.sdate;
                if( ss.edate     ) bbs.edate     = ss.edate;
                if( ss.publisher ) bbs.publisher = ss.publisher;
                if( ss.title     ) bbs.title     = ss.title;
                if( ss.category  ) bbs.category  = ss.category;
                if( ss.btype     ) bbs.btype     = ss.btype;
            }

            bbs.doSearch();
        });

        return {bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>